<script lang="ts" setup>
	import useSizes from "~/composables/useSizes";
	import { Sizes } from "~/utils/enums";

	const props = withDefaults(
		defineProps<{
			size?: Sizes;
		}>(),
		{
			size: "small",
		}
	);

	const { size } = toRefs(props);

	const { isSizeSmall, isSizeLarge, isSizeMedium } = useSizes(size);
</script>

<template>
	<svg class="icon-exports" v-if="isSizeLarge" width="72" height="64" viewBox="0 0 72 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect y="60.5" width="72" height="3" rx="1.5" fill="black" />
		<rect x="34" y="0.5" width="4" height="50" rx="2" fill="black" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.4668 34.4837C19.6857 35.2647 19.6835 36.5333 20.4645 37.3144L34.5356 51.3855C34.5475 51.3974 34.5595 51.409 34.5716 51.4205C34.5831 51.4326 34.5948 51.4446 34.6067 51.4565C35.3877 52.2376 36.6541 52.2376 37.4352 51.4566L51.5773 37.3145C52.3584 36.5334 52.3583 35.267 51.5772 34.486C50.7962 33.7049 49.5298 33.7048 48.7487 34.4859L36.0253 47.2093L23.2975 34.4814C22.5164 33.7004 21.2478 33.7026 20.4668 34.4837Z"
			fill="black"
		/>
	</svg>

	<svg class="icon-exports" v-if="isSizeMedium" width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect y="48" width="52" height="3" rx="1.5" fill="black" />
		<rect x="24.5" width="3" height="42" rx="1.5" fill="black" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.9188 28.0833C12.333 28.669 12.333 29.6188 12.9188 30.2046L24.9396 42.2254C25.5254 42.8112 26.4752 42.8112 27.0609 42.2254L39.0836 30.2028C39.6694 29.617 39.6694 28.6672 39.0836 28.0814C38.4978 27.4957 37.548 27.4957 36.9623 28.0814L26.0003 39.0434L15.0401 28.0833C14.4543 27.4975 13.5046 27.4975 12.9188 28.0833Z"
			fill="black"
		/>
	</svg>

	<svg class="icon-exports" v-if="isSizeSmall" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect y="20" width="24" height="2" rx="1" fill="black" />
		<rect x="11" width="2" height="17" rx="1" fill="black" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.05722 11.0929C6.6667 11.4835 6.66669 12.1166 7.05722 12.5071L11.2762 16.7261C11.28 16.7299 11.2838 16.7337 11.2876 16.7374C11.2917 16.7415 11.2957 16.7457 11.2998 16.7498C11.6904 17.1403 12.3235 17.1403 12.714 16.7498L16.9567 12.5071C17.3472 12.1166 17.3472 11.4835 16.9567 11.0929C16.5662 10.7024 15.933 10.7024 15.5425 11.0929L12.007 14.6285L8.47143 11.0929C8.08091 10.7024 7.44774 10.7024 7.05722 11.0929Z"
			fill="black"
		/>
	</svg>
</template>

<style scoped lang="scss">
	.icon-exports {
		rect,
		path {
			transition: all 0.2s ease-out;
		}
	}
</style>
